import React, { type FC, useCallback, useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Dropdown, Button, Modal, type MenuProps, notification } from 'antd'
import { DownOutlined, DeleteOutlined, CheckOutlined, StopOutlined, EditOutlined, LinkOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { useAppStore, type Store } from '../store'

interface ActionsMenuProps {
  store: Store
}

export const ActionsMenu: FC<ActionsMenuProps> = observer(({ store }) => {
  const [visible, setVisible] = useState(false)
  const [action, setAction] = useState<'delete' | 'activate' | 'deactivate' | null>(null)
  const [modalText, setModalText] = useState('')
  const [modalTitle, setModalTitle] = useState('')
  const { stores } = useAppStore()
  const navigate = useNavigate()

  const storeActions = {
    delete: (storeId: number) => {
      void (async () => {
        await stores.deleteStore(`${store.id}`)
        stores.setFilter({})
      })()
    },
    activate: (storeId: number) => {
      void (async () => {
        void stores.activateStore(store).then(() => {
          stores.setFilter({})
          notification.success({
            message: 'Store successfully activated'
          })
        }).catch(reason => console.error(reason))
      })()
    },
    deactivate: (storeId: number) => {
      void (async () => {
        void stores.deactivateStore(store).then(() => {
          stores.setFilter({})
          notification.success({
            message: 'Store successfully deactivated'
          })
        }).catch(reason => console.error(reason))
      })()
    }
  }

  const onClick: MenuProps['onClick'] = useCallback((v: any) => {
    switch (v.key as string) {
      case 'toStore':
        window.open(`http://${store.subdomain}.mycustard.com`, '_blank')
        break
      case 'toAdminPanel':
        window.open(`http://epanel.${store.subdomain}.mycustard.com/app`, '_blank')
        break
      case 'edit':
        navigate(`/stores/edit/${store.id}`)
        break
      case 'delete':
        setAction('delete')
        setModalTitle('Confirm Delete')
        setModalText('Are you sure you want to delete this store?')
        setVisible(true)
        break
      case 'activate':
        setAction('activate')
        setModalTitle('Confirm Activation')
        setModalText('Are you sure you want to activate this store?')
        setVisible(true)
        break
      case 'deactivate':
        setAction('deactivate')
        setModalTitle('Confirm Deactivation')
        setModalText('Are you sure you want to deactivate this store?')
        setVisible(true)
        break
      default:
        break
    }
  }, [stores, navigate, store, setVisible])

  const items: MenuProps['items'] = useMemo(() => [
    {
      label: 'Go to store',
      key: 'toStore',
      icon: <LinkOutlined />
    },
    {
      label: 'Go to store admin panel',
      key: 'toAdminPanel',
      icon: <LinkOutlined />
    },
    {
      label: 'Edit',
      key: 'edit',
      icon: <EditOutlined />
    },
    {
      label: 'Activate',
      key: 'activate',
      icon: <CheckOutlined />
    },
    {
      label: 'Deactivate',
      key: 'deactivate',
      icon: <StopOutlined />
    },
    {
      label: 'Delete',
      key: 'delete',
      icon: <DeleteOutlined />
    }
  ].filter(({ key }) => store.active ? key !== 'activate' : key !== 'deactivate'), [store])

  const handleOk = useCallback(() => {
    setVisible(false)
    if (action !== null) {
      storeActions[action](store.id)
    }
  }, [stores, store, setVisible, action])

  const handleCancel = useCallback(() => {
    setVisible(false)
  }, [setVisible])

  return (
    <>
      <Dropdown menu={{
        items,
        onClick
      }}>
        <Button>
          Actions <DownOutlined />
        </Button>
      </Dropdown>
      <Modal
        title={modalTitle}
        open={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        >
        <p>{modalText}</p>
      </Modal>
    </>
  )
})
